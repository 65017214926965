<template>
  <div class="warp">
    <div class="ql-editor" v-html="htmlData">{{htmlData}}</div>
  </div>
</template>

<script>
import "quill/dist/quill.core.css"
import "quill/dist/quill.snow.css"
import "quill/dist/quill.bubble.css"
import { noticeid } from '@/api/notice.js'
export default {
  data () {
    return {
      htmlData: undefined
    }
  },
  created() {
    this.noticeid()
  },
  methods: {
    noticeid() {
      noticeid(this.$route.query.id).then(res => {
        if (res.data.code === 200) {
          this.htmlData = res.data.data.noticeContent
        }
      })
    }
  }
}

</script>
<style lang="scss" scoped>
.warp {
  background-color: #fff;
  padding-bottom: 0;
}
</style>
